
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home"
import About from "./Pages/About"
import Contact from "./Pages/Contact"
import Termsandganditions from "./Pages/Termsandganditions";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import Cookies from "./Pages/Cookies";
import NoPage from "./Pages/NoPage";
import "./index.css";

function App() {
  return (

    <div>
      <BrowserRouter>
        <Routes>
          <Route index element={<Home />}/>
          <Route path="/Home" element={<Home />}/>
          <Route path="/About" element={<About />}/>
          <Route path="/Contact" element={<Contact />}/>
          <Route path="/Termsandganditions" element={<Termsandganditions />}/>
          <Route path="/PrivacyPolicy" element={<PrivacyPolicy />}/>
          <Route path="/Cookies" element={<Cookies />}/>
          <Route path="*" element={<NoPage />}/>
        </Routes>
      </BrowserRouter>
    </div>
          )}

export default App;
