import Header from "../Components/Header"
import Footer from "../Components/Footer"
import CookiesComp from "../Components/CookiesComp"


export default function Cookies() {
    return (
        <div>  <><Header /><CookiesComp /><Footer /></></div>
     
        )}
  
