
import Header from "../Components/Header"
import Footer from "../Components/Footer"
import ContactComp from "../Components/ContactComp"

export default function About() {
    return (
        <div> <><Header /><ContactComp/><Footer /></></div>
     

        
        )}
  
