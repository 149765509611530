
import Header from "../Components/Header"
import Footer from "../Components/Footer"
import AboutComp from "../Components/AboutComp"

export default function Contact() {
    return (
        <div>  <><Header /><AboutComp /><Footer /></></div>
     
        )}
  
