import Header from "../Components/Header"
import Footer from "../Components/Footer"
import TermsandConditionsComp from "../Components/TermsandConditionsComp"

export default function Termsandganditions() {
    return (
        <div>  <><Header /><TermsandConditionsComp /><Footer /></></div>
     
        )}
  
