
import "./MyStyle.css"
import { Link } from "react-router-dom";


export default function Homepagemainbody(){
return(
    <body>    

    <p class="main">We build the tools you want!</p>

    <p class="main2">Websites and Apps that meet your needs!</p>

    <p class="main3">Our work!</p>

 
    <a href="https://windsurfingnewsgreece.com/" class="listoftopproducts">Windsurfing News Greece</a>


    <p class="main5">Why choose 444</p>

    <div class="whychoose444">

        <div class="boxone">

            <p class="firstboxtitle"> Products that work!</p>
            <p class="firstbox">Our tools are designed with simplicity,
            reliability, and efficiency in mind.
            We make tools that work, so you can focus on what matters.</p>

        </div>

        <div class="boxtwo">

            <p class="firstboxtitle"> Fast Solutions!</p>
            <p class="firstbox">Whether you need a website, an app, or any other digital product,
            we can deliver it to you in no time. 
            We provide you with a fast solution according to your needs.</p>

            </div>

        <div class="boxthree">

        <p class="firstboxtitle"> Personalize!</p>
        <p class="firstbox">We always offer personalized solutions to our clients. 
            Each project has its own unique challenges and opportunities, 
            and we tailor our approach accordingly.</p>

        </div>
    </div>


    <div class="testimonialbox">

        <div class="testimonials">
            <p class="testimonialsone"> Our goal is to deliver fast and reliable Products!</p>
            </div>
    
            <div>
        <p class="testimonialstwo">We are driven by a passion for creating solutions that empower you to achieve your goals. Whether you are an individual, 
            a small business, or a large corporation, we have the tools you need to succeed in your endeavors. 
            Our vision is to become the major player who makes tools that help you succeed.</p>
    </div>

    </div>



    <div class="contactbox">
        <p class="textone"> Are you interested?</p>
        <p class="texttwo"> Set your appointment!</p>

        <Link to="/Contact">
            <button class="contacus"> Contact Us</button>
        </Link>
        
    </div>
     
               
</body>

)


}
