
import "./MyStyle.css"
import { Link } from "react-router-dom";

export default function Header(){
return(
   
    <div class="header">

        <Link to="/Home" style={{ textDecoration: 'none' }}>
            <a class="logoheader">
            444
            </a>
        </Link>

    
        <Link to="/Contact">
            <button class="contacus"> Contact Us</button>
        </Link>
    </div>

)}