
import Header from "../Components/Header"
import Footer from "../Components/Footer"
import Homepagemainbody from "../Components/Homepagemainbody"

export default function Home() {
    return (
 
<div> <><Header /><Homepagemainbody /><Footer /></></div>
        
        
     
        )}
  
