
import "./MyStyle.css"
import { Link } from "react-router-dom";


export default function Footer(){
return(
    <div class="hrnew">
    

           
        <div class="footer">

     
            
            <div class="footerTitle">
                <p>
                    Products
                </p>
       
            

            <div class="footercategories">
                
                <a>
                    Websites
                </a>
                
                <a>
                    Extentions   
                </a>
                <a>
                    Apps   
                </a>
            </div>
            
        </div>

        
            <div class="footerTitle">
                <p>
                    Resources
                </p>
      
            
            <div class="footercategories">
                
                <a>
                    News
                </a>

                <a>
                    FAQ
                </a>
            </div>
            
        </div>


     
            
            <div class="footerTitle">
                <p>
                    Company
                </p>
                
           
            

            <div class="footercategories">

                <Link to="/About">
                      About Us
                </Link>

                <a >
                    Careers
                </a>

                <Link to="/Termsandganditions">
                Terms and Conditions
                </Link>

                <Link to="/PrivacyPolicy">
                Privacy and Policies
                </Link>

                <Link to="/Cookies">
                Cookies
                </Link>

             

            </div>
            
        </div>


        </div>
        
        <Link to="/Home" style={{ textDecoration: 'none' }}>
            <a class="logofooter">
            444
            </a>
        </Link>


        <div class="credits">
        <p > Copyright © 2023. All rights reserved.</p>
        </div>

   </div>
)


}
