
import "./MyStyle.css"

export default function PrivacyPolicyComp(){
return(

    <body>

    <div class="termsandconditiontext" >

        <h1>Privacy Policy</h1>

        <h2> Last Updated On 31-Jan-2024</h2>

        <p>This Privacy Policy describes the policies of https://3-four.com/ on the collection, use and disclosure of your information that we collect when you visit and/or use our website https://3-four.com/ (the “Service”). 
            By accessing or using the Service, you are consenting to the collection, use and disclosure of your information in accordance with this Privacy Policy. If you do not consent to the same, please do not access or use the Service. 
            We may modify this Privacy Policy at any time without any prior notice to you and will post the revised Privacy Policy on the Service. The revised Policy will be effective upon its posting and your continued access or use of the Service will constitute your acceptance of the revised Privacy Policy. We therefore recommend that you periodically review this page. 
            The data controller regarding all personal data processing operations carried out through https://3-four.com/ is KARACHISARIDIS EFSTATHIOS TOU VASILEIOU, Marathon Greece, email: privacy@3-four.com.</p>

        <h2>INFORMATION WE COLLECT:</h2>

        <p>We will collect and process the following personal information about you:</p>
       <ul>
        <li>Name</li>
        <li>Email</li>
        <li>Company name</li>
        <li>Information that you voluntarily choose to submit during the use of our services.</li>
        <li>Photos and videos that you submit to our services. </li>
        <li>Any other information that you voluntarily choose to disclose when you contact us via email, telephone etc</li>
        <li>Navigation data (for more information, please refer to our Cookie Policy)</li>
    </ul>
        <h2> HOW WE USE YOUR INFORMATION:</h2>

        <p>We will use the information that we collect about you for the following purposes, subject to your consent, if required, based on applicable law:</p>
        <ul>
            <li>Marketing/ Promotional</li>
            <li>Customer feedback collection</li>
            <li>Support</li>
            <li>Administration info</li>
            <li>Targeted advertising </li>
            <li>Site/services protection</li>
        </ul>

        <p>If we want to use your information for any other purpose, we will duly inform you and ask you for consent, unless we are required to do otherwise by law.</p>

        <h2> HOW WE SHARE YOUR INFORMATION: </h2>

        <p>We will not sell, exchange, lease personal data to third parties, natural or legal persons. We may disclose personal data only to the extent necessary as follows:</p>
      
        <h2>Promotional Messages</h2>

        <p> 3-four.com may send promotional communication (e.g. newsletters) to you, subject to your consent. You may unsubscribe from this service at any time via any of our disclosed methods.</p>

        <ul>
            <li>To our duly appointed collaborators/service providers (e.g. internet service providers, IT service providers and providers of related infrastructure, customer service providers, attorneys-at-law, accountants</li> 
            <li>Selected individuals that we have authorized to process data that are strictly necessary for the carrying out of activities relating to the provision of the services through the Website (e.g. technical maintenance of network equipment and electronic communications networks), who have undertaken an obligation of confidentiality or are subject to an appropriate legal obligation of confidentiality</li> 
            <li>Credit institutions (e.g. for the carrying out of payments)</li> 
            <li>Public authorities, bodies or offices, to include tax authorities, to the extent necessary in compliance with applicable law, regulation, court order or other legal process</li> 
            <li>In the event of a corporate change, reorganization, merger, sale etc., your personal data may be disclosed. In this case, we will make every effort to adequately inform you and will provide you with the possibility to exercise your rights.   </li>                  
        </ul>
        <h2> RETENTION OF YOUR INFORMATION:</h2>
        <p>We will retain your personal information with us for 90 days to 2 years after users terminate their accounts or for as long as we need it to fulfill the purposes for which it was collected as detailed in this Privacy Policy. We may need to retain certain information for longer periods such as record-keeping / reporting in accordance with applicable law or for other legitimate reasons like enforcement of legal rights, fraud prevention, etc. Anonymized information, that does not identify you (directly or indirectly), may be stored indefinitely.</p>
     
        <h2>YOUR RIGHTS: </h2>

        <p>Depending on the law that applies, you may have a right to access and rectify or erase your personal data or receive a copy of your personal data, restrict or object to the active processing of your data, ask us to share (port) your personal information to another entity, withdraw any consent you provided to us to process your data, a right to lodge a complaint with a statutory authority and such other rights as may be relevant under applicable laws. To exercise these rights, you can write to us at privacy@3-four.com. We will respond to your request in accordance with applicable law. 
            You may opt-out of direct marketing communications or the profiling we carry out for marketing purposes by writing to us at privacy@3-four.com. 
            Do note that if you do not allow us to collect or process the required personal information or withdraw the consent to process the same for the required purposes, you may not be able to access or use the services for which your information was sought.</p>

        <h2>TRANSFERS OF DATA OUTSIDE THE EU:</h2>

        <p>We wish to reassure you that in the limited and necessary circumstances that your personal data will be transferred outside the EU/EEA, we will put in place adequate measures and will provide appropriate safeguards, in order to ensure the privacy and security of your personal data. You may obtain additional information regarding such safeguards, by emailing us at  privacy@3-four.com.</p>

        <h2>MINORS:</h2>
            <p>In compliance with the laws that protect minors’ online privacy, we do not purposely collect personal data of individuals under the age of 18. In case we are informed that we have unintentionally collected minors’ personal data, such information will be promptly deleted.</p>

        <h2>COOKIES ETC.</h2>
            <p>To learn more about how we use these and your choices in relation to these tracking technologies, please refer to our Cookie Policy.</p>

        <h2>SECURITY:</h2>
            <p>The security of your information is important to us and we will use reasonable security measures to prevent the loss, misuse or unauthorized alteration of your information under our control. However, given the inherent risks, we cannot guarantee absolute security and consequently, we cannot ensure or warrant the security of any information you transmit to us and you do so at your own risk.</p>

        <h2>THIRD PARTY LINKS:</h2>
            <p>Our Service may contain links to other websites that are not operated by us. This Privacy Policy does not address the privacy policy and other practices of any third parties, including any third party operating any website or service that may be accessible via a link on the Service. We strongly advise you to review the privacy policy of every site you visit. We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services. For any request or query that you may have regarding this Policy, please contact us by: Email: privacy@3-four.com</p>

        <h2>We will make every effort to respond to your query the soonest possible and will address your concerns in accordance with applicable law.</h2>

        </div>




</body>


        
)}
