
import Header from "../Components/Header"
import Footer from "../Components/Footer"
import PrivacyPolicyComp from "../Components/PrivacyPolicyComp"

export default function PrivacyPolicy() {
    return (
        <div>  <><Header /><PrivacyPolicyComp/><Footer /></></div>
     
        )}
  
