
import "./MyStyle.css"

export default function CookiesComp(){
return(

    <body>

    <div class="termsandconditiontext" >

        <h1>Cookie Policy</h1>

        <h2>Last Updated On 31-Jan-2024</h2>

            <p> We use cookies on https://3-four.com/ website to manage sessions, provide personalized web pages, and tailor advertising and other content to reflect your particular needs and interests. Cookies also help make the website work properly, make it more secure, provide a better user experience, understand how the website works and analyze where it needs improvement. </p>
    
        <h2>WHAT ARE COOKIES? </h2>

            <p>“Cookies” are small pieces of files in text form, which are stored in the User’s browser when visiting a website. The information stored on the User’s computer may contain information such as which pages were visited, the date and time of the visit as well as a random and unique identification number, etc. With this use, the website is able to store useful information about the User’s browsing on the website, as well as to read this information in order to offer the User a unified browsing experience.
                There are two different types of cookies: Session and Persistent. They are used for different functions and contain different information: Session cookies contain information used in the current browsing of your browser. These cookies are automatically deleted when you close your browser. Nothing remains on your computer after you leave the site. 
                Persistent cookies are used to store information used between visits. This data allows websites to recognize that you are a customer who returns to them once again and reacts accordingly. Persistent cookies have a lifespan defined by the website, which can range from a few minutes or even several years.</p>

        <h2> WHAT COOKIES DO WE USE ON OUR WEBSITE?</h2>
                <p>The website (https://3-four.com/) may incorporate “embed” photos and videos from other websites. As a result, when you visit a page of the website (https://3-four.com/) that contains embedded material, you may be exposed to Cookies of the aforementioned websites.
                    Among the Cookies used on the website (https://3-four.com/) per category are the following:</p>
  
        <h3> Necessary</h3>
                    <p>Necessary cookies are required to enable the basic features of this site, such as providing secure log-in or adjusting your consent preferences. These cookies do not store any personally identifiable data.</p>
                    <table>
                        <tr>
                        <th>Cookie</th>
                        <th>Duration</th>
                        <th>Purpose</th>
                        </tr>
                        <tr>
                        <td>cookieyes-consent</td>
                        <td>3-four.com</td>
                        <td>1 year</td>
                        </tr>
                    </table>
      
        <h3>Functional</h3>
                    <p>Functional cookies help perform certain functionalities like sharing the content of the website on social media platforms, collecting feedback, and other third-party features.</p>
                    <table>
                        <tr>
                        <th>Cookie</th>
                        <th>Duration</th>
                        <th>Purpose</th>
                        </tr>
                        <tr>
                        <td>No cookies yet</td>
                        <td>-</td>
                        <td>-</td>
                        </tr>
                    </table>

        <h3>Analytics</h3>
                    <p>Analytical cookies are used to understand how visitors interact with the website. These cookies help provide information on metrics such as the number of visitors, bounce rate, traffic source, etc.</p>
                    <table>
                        <tr>
                        <th>Cookie</th>
                        <th>Duration</th>
                        <th>Purpose</th>
                        </tr>
                        <tr>
                        <td>No cookies yet</td>
                        <td>-</td>
                        <td>-</td>
                        </tr>
                    </table>

        <h3>Advertisement</h3>
                    <p>Advertisement cookies are used to provide visitors with customized advertisements based on the pages you visited previously and to analyze the effectiveness of the ad campaigns.</p>
                    <table>
                        <tr>
                        <th>Cookie</th>
                        <th>Duration</th>
                        <th>Purpose</th>
                        </tr>
                        <tr>
                        <td>No cookies yet</td>
                        <td>-</td>
                        <td>-</td>
                        </tr>
                    </table>
        <h2>MANAGE COOKIE PREFERENCES</h2>
                        <p>It is at your discretion to revoke, whenever you want, your consent or to oppose the use of Cookies on your computer or device, to control and / or delete cookies.You can change your cookie preferences at any time by clicking here. This will allow you to revisit the cookie consent form and change your preferences or withdraw your consent immediately. In addition, different browsers provide different methods of blocking and deleting Cookies used by websites. You can change your browser settings to block/delete Cookies. Below are the links to the support documents on how to manage and delete Cookies from the most common web browsers. 
                            If you are using any other web browser, please visit your browser’s official support documents. </p>
                    
                        <ul>
                           <p>Chrome: <a href="https://support.google.com/chrome/answer/95647?hl=en&co=GENIE.Platform%3DDesktop">Here</a></p>
                           <p>Safari:  <a href="https://support.apple.com/kb/index?page=search&type=suggested&locale=en_US&q=cookies+safari&src=globalnav_support">Here</a></p>
                           <p>Firefox:  <a href="https://support.mozilla.org/en-US/kb/websites-say-cookies-are-blocked-unblock-them">Here</a></p>
                           <p>Microsoft Edge: <a href="https://support.microsoft.com/en-us/microsoft-edge/view-cookies-in-microsoft-edge-a7d95376-f2cd-8e4a-25dc-1de753474879">Here</a></p>
                        
                        </ul>
        
        <h2>CHANGES TO THE COOKIE POLICY</h2>
                        <p>This Cookies Policy may be modified at any time. For this reason, we consider it appropriate to refer regularly to this Policy.</p>
                
                
    </div>




</body>

        
)}
