
import Header from "../Components/Header"
import Footer from "../Components/Footer"
import { Link } from "react-router-dom"


export default function NoPage() {
    return (
        <div>  <><Header /><div style={{
            marginTop: "15vh" }}>
           <h1>Error!
            The page you looking for is not exist...</h1> 
            <Link to="/Home" style={{ textDecoration: 'none' }}>
            <h2>
            Click here to go Home
            </h2>
        </Link>
            </div><Footer /></></div>
     
        )}
  
