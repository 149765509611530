
import "./MyStyle.css"

export default function ContactComp(){
return(

        <body>

            <p class="aboutustitle"> About Us </p>

            <div class="aboutusmain"  style={{
          marginTop: "5vh" }}>
            
            
                <div class="aboutustext">

                    <h1 style={{ fontSize: '3em' }}>About 444</h1>

                    <p class="aboutusletters"> Welcome to the digital frontier! We are a vibrant and innovative website and app development company that thrives on creating tools that empower people and transform lives. Our journey began with a simple yet profound belief: technology should solving real-world problems. 
                    <br/><br/> Our mission is to design and develop intuitive, user-friendly websites and apps that cater to the needs of individuals and businesses alike. We understand that in the fast-paced digital world, the human element is often overlooked. That's why we prioritize accessibility, usability, and personalization in every project we undertake. We believe that by focusing on these core values, we can create digital tools that not only meet but exceed our users' expectations. 
                    <br/><br/> We work closely with our clients to understand their vision and objectives, ensuring that the final product is not just a technological solution but a strategic asset that drives growth and success. Join us on this exciting journey as we explore the limitless possibilities of technology and create a future where everyone has the tools they need to succeed. 
                    <br/><br/>  For inquiries, collaborations, or to learn more about our services, please feel free to reach out. We are eager to hear from you and explore how we can bring your ideas to life.
                        <br/><br/>Welcome to our story; let's make history together!</p>

                </div>
                
                
            </div>


        </body>
        
)}
