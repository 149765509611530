
import "./MyStyle.css"

export default function TermsandConditionsComp(){
return(

    <body>

    <div class="termsandconditiontext" >

        <h1>Terms and Conditions</h1>

        <h2>Governing Law - Jurisdiction</h2>

        <h2> Last Updated On 7-Dec-2023</h2>

        <p> These Terms of Service and your use of the Website shall be governed by and construed in accordance with the laws of Greece. The Courts of Athens shall have exclusive jurisdiction over any disputes arising in connection with the present Website. </p>
    
        <p> Thank you for visiting 3-four.com (the “Website”), operated by KARACHISARIDIS EFSTATHIOS TOU VASILEIOU sole proprietorship (“us”, “we” or “our”), duly organized under the laws of Greece, with registered offices at Marathon, Greece, with the Commercial Title “444”.</p>
    
        <p>  These Terms of Service describe our commitments to you, and your rights and responsibilities when using our services. Please read them carefully and reach out to us if you have any questions. If you don’t agree to these Terms, don’t use our services.</p>
    
        <p> The Website is strictly for your personal, non-commercial use. You agree that you are solely responsible for all costs and expenses you may incur in relation to your use of the Website and keeping your password and other account details confidential.</p>
    
        <p> The content and information included on the Website constitute an offer to its visitors and users in general and to the community of internet users and cannot in any case be used as a solicitation of business or to encourage unsafe or illegal behaviors.</p>
    
        <h2>Copyright </h2>

        <p> The Website contains various materials, including without limitation text, comments, icons, images, messages, photographs, audio, video and other content (collectively, “Content”), which are subject to copyright protection. Except for that information which is in the public domain or for which you have been given express written permission by us, no Content may be sold, leased, copied, reproduced, republished, uploaded, displayed, posted, transmitted, distributed, modified, publicly performed, used in any derivative works based thereon or otherwise used in any form and by any means for any public or commercial purpose without our prior written consent. However, you may print copies of materials from the Website for your personal, non-commercial use only, provided that you keep intact all copyright, trademark and other proprietary notices appearing therein and that you use such materials solely in the manner permitted by the present Terms of Service. </p>
    
        <p> The trademark “444” and all names, graphics, designs, logos, page headers, button icons, scripts, commercial markings and service names appearing on the Site (collectively, the “Trademarks”) belong exclusively to Us, our suppliers or other third parties, as indicated. You may not use, copy, reproduce, republish, distribute or modify any Trademark in any way, including but not limited to distribution of Content for advertising or publicity or otherwise, without our prior written consent or the consent of such third-party Trademark holder, as applicable. Please be advised that we actively and aggressively enforce our intellectual property rights to the fullest extent permitted by law.</p>
    
        <h2> Embedded content from other websites </h2>

        <p> Articles or posts on this site may include embedded content (e.g. videos, images, articles, etc.). Embedded content from other websites behaves in the exact same way as if the visitor has visited the other website. These websites may collect data about you, use cookies, embed additional third-party tracking, and monitor your interaction with that embedded content, including tracking your interaction with the embedded content if you have an account and are logged in to that website.</p>

        <h2> Hyperlinks</h2>

        <p> The Website may contain links to other websites maintained by third-parties. These links are provided solely as a convenience and does not imply endorsement of, or association with, the party by 3-four.com.</p>

        <p> Our Website does not control the availability, content, privacy policy, quality and completeness of the services of other websites and pages to which it refers through “links”, hyperlinks or advertising banners. The above links have been placed exclusively for the convenience of visitors/users of the website, while the websites to which they refer are subject to the corresponding terms of use of these websites. The placement of the links does not constitute an indication of approval or acceptance of the content of the respective websites by the website (3-four.com), therefore, for any problem encountered during visit/use, the user must contact the respective websites and pages directly, which bear full responsibility for the provision of their services. The website (3-four.com) should in no way be considered as endorsing or accepting the content or services of the websites and pages to which it refers or that it is connected to them in any other way.</p>
    
        <h2> Internet Disclaimers and Limitations of Liability</h2>
    
        <p> We make every effort to ensure that all the Content and information displayed on our Website are characterized by accuracy, clarity, correctness, completeness, timeliness and availability. However, in no case does the Website guarantee and is therefore not responsible (not even through negligence) for any damage that may be caused to the visitor / user, from its use. The Content and Services are provided by the website “as is”, without any warranty express or implied. In no case is the website (3-four.com) liable for any possible damage (positive or negative, which indicatively and not limitingly, consists of loss of profits, data, missed profits, monetary satisfaction, etc.) from use of the website (3-four.com), or third parties, for reasons related to the operation or not, any inability to provide services / information available from it or from any unauthorized interventions by third parties in products / services / information available through it.</p>
    
        <p> We make every possible effort for the good operation of our network, but in no case do we guarantee the functions of our Website.</p>
    
        <h2> Information we collect </h2>

        <p>Please refer to our Privacy Policy and our Cookie Policy for more information on the types of data we collect, how we use such data and the rights that you have as a data subject.</p>
    
        <h2>Promotional Messages</h2>

        <p> 3-four.com may send promotional communication (e.g. newsletters) to you, subject to your consent. You may unsubscribe from this service at any time via any of our disclosed methods.</p>

        <h2> Modifications to this Agreement.</h2>

        <p> 3-four.com reserves the right to change, modify, add, or remove portions any of the terms and conditions contained in this Agreement at any time. You acknowledge and agree that it is your responsibility to review the Website and these Terms of Service from time to time. Your continued use of the Website after such modifications to this Agreement will constitute acknowledgment of the modified Terms of Service and agreement to abide and be bound by the modified Terms of Service.  </p>

        <h2>Permissions </h2>

        <p> It is expressly prohibited to use, reproduce, republish, copy, store, sell, transmit, distribute, issue, execute, download, translate, modify in any way, partially or in summary, the content of the Website and the services offered on this Website, without prior written permission. Exceptionally, it is permitted to individually save and copy parts of the content on a simple personal computer for strictly personal use, without the intention of commercial or other exploitation and always under the condition of indicating its source, without this implying in any way the granting of intellectual rights property.</p>

        <p>Our content often includes other people's content. So you'll have to ask them if you can use it.</p>

        <p>You'll need to get our permission first for any business use, and you might have to pay a fee. When you share to a social media platform their terms will apply. Do read their terms (which you can search for online).</p>

        <p>Content submitted and posted by users remains their property. Nevertheless, for any content that users submit to the website (3-four.com) for publication, they automatically grant the website an unlimited, free of charge, license to use, distribute, reproduce, modify, adapt, publicly display and exploit the content at any time and in any electronic way, without any time limitation.</p>

        <h2>Date of Last Update.</h2>

        <p>This agreement was last updated on December 2023.</p>

        <p>If you have a question, concern or complaint about these Terms, you can contact us by email at: info@3-four.com</p>

    </div>




</body>
    

        
)}
