
import "./MyStyle.css"

export default function AboutComp(){
return(

    <body style={{
        marginTop: "10vh" }}>
    <p>Contact Us</p>
    <p>Please fill out the following form:</p>

    <form>
      <label htmlFor="fname">First name:</label>
      <input type="text" id="fname" name="fname" />

      <label htmlFor="lname">Last name:</label>
      <input type="text" id="lname" name="lname" />

      <label htmlFor="cname">Company name:</label>
      <input type="text" id="cname" name="cname" />

      <label htmlFor="email">Email Address:</label>
      <input type="email" id="email" name="email" />

      <label htmlFor="sname">Subject:</label>
      <input type="text" id="sname" name="sname" />

      <input type="submit" value="Submit" />
    </form>
  </body>
       
        
)}
